@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: ADNOC Sans;
  src: url('./fonts/Adnoc/ADNOC Sans XBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: ADNOC Sans;
  src: url('./fonts/Adnoc/ADNOC Sans Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: ADNOC Sans;
  src: url('./fonts/Adnoc/ADNOC Sans Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: ADNOC Sans;
  src: url('./fonts/Adnoc/ADNOC Sans Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

:root {
  --font-ADNOC: 'ADNOC Sans';
}

body {
  font-family: ADNOC Sans !important;
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 3.6px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #00bfb2;
  border-radius: 11.7px;
  transition: all 0.3s ease;
}

body {
  @apply text-taziz-blue;
  font-family: Tahoma, Verdana, Segoe, sans-serif;
}

p {
  margin-bottom: 12px !important;

  &:last-of-type {
    margin-bottom: 0px !important;
  }
}

/* LogoCarousel */
@keyframes scroll {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

ul {
  @apply ps-6;

  li {
    @apply list-disc;
  }
}

/*swiper*/
$pause-on-hover: paused;
$pause-on-click: paused;
$width: 100%;
$transform: none !important;
/*swiper wrapper*/
$play: running;
$direction: normal;
$duration: 84s;
$delay: 0s;
$iteration-count: infinite;
$min-width: auto;

.LogoCarousel {
  .linear-timing-swiper {
    .myWrapper {
      flex: 0 0 auto;
      min-width: $min-width;
      z-index: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      animation: scroll $duration linear $delay $iteration-count;
      animation-play-state: $play;
      animation-delay: $delay;
      animation-direction: $direction;

      [dir='rtl'] & {
        animation-direction: reverse;
      }
    }

    .swiper-wrapper {
      overflow-x: hidden !important;
      display: flex !important;
      flex-direction: row !important;
      position: relative;
      width: $width;
      transform: $transform;
      height: fit-content;

      [dir='rtl'] & {
        flex-direction: row-reverse !important;
      }
    }

    .swiper:hover,
    .swiper:focus,
    .swiper.clicked {
      .myWrapper {
        animation-play-state: $pause-on-hover;
      }
    }
  }

  .no-animation {
    .swiper-wrapper {
      animation: none !important;
      transition: none !important;
    }
  }
}

/* TimelineCarousel */
.TimelineCarousel {
  @media (min-width: theme('screens.lg')) {
    .swiper-slide-prev,
    .swiper-slide-next {
      .TimelineCarousel_SlideItem {
        opacity: 0;

        .YearText {
          @apply typo-h1 opacity-0;
        }

        h4 {
          @apply typo-h3 opacity-0;
        }

        .typo-p-large {
          @apply typo-h4 opacity-0;
        }
      }
    }
  }
}

/* OurPlanCarousel */
.TabbedCarousel {
  .ProgressBar {
    &.inactive {
      animation: TabbedCarousel-progress-decrease 1s ease;
    }

    &.active {
      animation: TabbedCarousel-progress-increase 10s linear;
    }
  }

  .TabbedCarousel_mainSwiper {
    .swiper-slide {
      opacity: 0 !important;
    }

    .swiper-slide-active {
      opacity: 1 !important;
    }
  }

  @keyframes TabbedCarousel-progress-decrease {
    0% {
      width: 100%;
    }

    100% {
      width: 0%;
    }
  }

  @keyframes TabbedCarousel-progress-increase {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }
}

.anti-container {
  width: calc(100% + 32px);
  margin-left: -16px;

  // Breakpoints
  @media (min-width: theme('screens.sm')) {
    margin-left: -24px;
  }

  @media (min-width: theme('screens.md')) {
    margin-left: -48px;
  }

  @media (min-width: theme('screens.lg')) {
    margin-left: -96px;
  }

  @media (min-width: theme('screens.xl')) {
    margin-left: -156px;
  }

  // RTL Support
  [dir='rtl'] & {
    margin-left: auto;
    margin-right: -16px;

    // Breakpoints for RTL
    @media (min-width: theme('screens.sm')) {
      margin-right: -24px;
    }

    @media (min-width: theme('screens.md')) {
      margin-right: -48px;
    }

    @media (min-width: theme('screens.lg')) {
      margin-right: -96px;
    }

    @media (min-width: theme('screens.xl')) {
      margin-right: -156px;
    }
  }
}

.link {
  @apply text-taziz-green typo-p-large cursor-pointer;
}

.lg-paragraph {
  @apply typo-p-large;
}

.list {
  @apply typo-p-large;
}

form > fieldset {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 18px;
}

form > fieldset > fieldset {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 16px;

  @media (min-width: theme('screens.lg')) {
    gap: 24px;
  }
}

form > fieldset > fieldset:first-child {
  gap: 16px;

  @media (min-width: theme('screens.lg')) {
    gap: 24px;
  }
}

.mapboxgl-ctrl-bottom-left {
  display: none !important;
}

.mapboxgl-ctrl-bottom-right {
  display: none !important;
}
